@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #ffffff;
  --grey: #d1d2d4;
  --lightGrey: #f5f5f5;
  --darkTurquoise: #255262;
  --darkBlue: ##0c4052;
  --black: #000000;
  --red: #e12726;
  --cuzBlue: #064b76;
}

/* FONT: Helvetica Neue */
@font-face {
  font-family: "KGHappy-solid";
  src: url("../assets/font/KGHAPPYSolid.ttf") format("truetype");
  font-display: swap;
}

/* FONT: Helvetica Neue */
@font-face {
  font-family: "helvetica-neue";
  src: url("../assets/font/HelveticaNeue.ttc") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "helvetica-neue-thin";
  src: url("../assets/font/HelveticaNeueLight.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "helvetica-neue-bold";
  src: url("../assets/font/HelveticaNeueLTPro-Bd.otf") format("truetype");
  font-display: swap;
}

/* FONT: inter-thin */
@font-face {
  font-family: "inter-thin";
  src: url("../assets/font/InterThin.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "inter";
  src: local("inter"), url("../assets/font/InterRegular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "inter-bold";
  src: local("inter-bold"),
    url("../assets/font/InterBold.ttf") format("truetype");
  font-display: swap;
}

/* FONT: dmsans */
@font-face {
  font-family: "dmsans-thin";
  src: url("../assets/font/DMSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "dmsans";
  src: local("dmsans"),
    url("../assets/font/DMSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "dmsans-bold";
  src: local("dmsans-bold"),
    url("../assets/font/DMSans-Bold.ttf") format("truetype");
  font-display: swap;
}

/* FONT: playfair black italic */
@font-face {
  font-family: "playfair-black-italic";
  src: local("playfair-black-italic"),
    url("../assets/font/PlayfairDisplay-BlackItalic.ttf") format("truetype");
  font-display: swap;
}

/* FONT: poppins */
@font-face {
  font-family: "poppins";
  src: local("poppins"),
    url("../assets/font/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

* {
  font-family: "helvetica-neue-thin", sans-serif;
}

@layer base {
  /* heading 1 */
  .main-heading {
    @apply text-[65px] leading-[65px] font-bold;
  }
  .faq-heading {
    @apply text-[72px] leading-[72px] font-bold;
  }

  /* heading 2 */
  .section-heading {
    @apply text-[32px] leading-8 font-bold;
  }
  /* heading 3 */
  .data-heading {
    @apply text-[20px] leading-6 font-bold;
  }
  /* p {
    @apply text-base leading-6 font-normal;
  } */
  p {
    @apply leading-6 font-normal;
  }

  /* for tosca text */
  .text-tosca {
    @apply text-cuzTosca;
  }

  /* standard text */
  .text {
    @apply text-cuzRed;
  }

  /* white text */
  .white-text {
    @apply text-cuzWhite;
  }

  /* for white button */
  .white-button {
    @apply px-[25px] text-cuzBlack font-semibold bg-cuzWhite rounded-lg;
  }

  .teal-button {
    @apply px-[8px] xs:px-[15px] s:px-[25px] text-cuzWhite font-semibold bg-cuzTurquoise rounded-lg cursor-pointer;
  }

  /* for grey button */
  .grey-button {
    @apply py-[18px] px-[25px] gap-[10px] text-cuzBlack bg-cuzLightGrey rounded-lg;
  }

  /* for red button */
  .red-button {
    @apply py-[15px] px-[25px] gap-[10px] text-cuzBlack bg-cuzRed rounded-[30px];
  }

  /* section : based margin */
  .section-margin {
    @apply my-[90px] xxs:px-2 xs:px-8 md:px-16 hd:px-[120px];
  }
}

@layer components {
  /* grid components large */
  .grid-2-compontent {
    @apply w-full grid grid-cols-2;
  }

  /* grid components medium */
  .grid-3-compontent {
    @apply w-full grid grid-cols-3;
  }

  /* footer parts */
  .footerpart-1 {
    @apply w-full bg-cuzLightGrey;
  }
  .footerpart-2 {
    @apply w-full bg-cuzRed;
  }

  .footer-input {
    border-bottom: 1px solid white !important;
  }
}

/* .section-container {
  @apply max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto;
} */

.section-container {
  @apply max-w-[88%] hd:max-w-[100%] xl:max-w-[1200px] hdl:max-w-[1440px] fhd:max-w-[1800px] mx-auto;
}

.section-margin-x {
  @apply hd:px-[120px];
}

@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

/* reset */
button,
fieldset {
  all: unset;
}

/* Wira Budaya -UnderConstruction */
.PopoverContent {
  border-radius: 4px;
  padding: 20px;
  z-index: 10000;
  /* width: 260px; */
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.PopoverContent:focus {
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px var(--violet7);
}
.PopoverContent[data-state="open"][data-side="top"] {
  animation-name: slideDownAndFade;
}
.PopoverContent[data-state="open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}
.PopoverContent[data-state="open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.PopoverContent[data-state="open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.PopoverArrow {
  fill: white;
}

.PopoverClose {
  font-family: inherit;
  cursor: pointer;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  position: absolute;
  top: 5px;
  right: 5px;
}
.PopoverClose:hover {
  background-color: var(--violet4);
}
.PopoverClose:focus {
  box-shadow: 0 0 0 2px var(--violet7);
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
}
.IconButton:hover {
  background-color: var(--violet3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.splide__slide.is-active img {
  transform-origin: left;
  transform: scale(1);
}

.splide__slide.is-next img {
  transform-origin: center !important;
}

.splide__slide.is-prev img {
  transform-origin: center !important;
}

@media only screen and (max-width: 576px) {
  /* .splide__slide.is-active img {
    transform-origin: center;
    transform: scale(0.9);
  } */

  /* .splide__arrow--next {
    margin-right: 1rem;
  }

  .splide__arrow--prev {
    margin-left: 1rem;
  } */
}

@media only screen and (max-width: 1800px) {
  .splide__slide.is-next img {
    transform-origin: left !important;
  }

  .splide__slide.is-prev img {
    transform-origin: right !important;
  }
}

.force-white,
.force-white > img,
.force-white > p {
  color: white !important;
}
.force-white .prose p {
  color: white !important;
}
.force-white > img {
  fill: white; /* If the <img> is an SVG, use 'fill' property to set the color */
}

.prose {
  max-width: none !important;
}

.text-big {
  @apply md:text-[24px] text-[18px];
}

.masthead {
  transform: skewX(-9deg);
  transform-origin: top right;
}

/* Wira Budaya */
body {
  background-image: url("../assets/img/hero-icon.png");
  background-repeat: repeat-y;
  background-size: auto;
}

.section-hero {
  background-image: url("../assets/img/hero-icon.png");
  background-repeat: repeat-x;
  background-size: auto 150px;
}

/* 80% */
.section-container-wider {
  @apply max-w-[74.8%] hd:max-w-[76.5%] xl:max-w-[1020px] hdl:max-w-[1032px] hd2xl:max-w-[1305.6px] mx-auto;
}
/* 95% */
/* .section-container-wider {
  @apply max-w-[83.6%] hd:max-w-[85.5%] xl:max-w-[1140px] hdl:max-w-[1216px] hd2xl:max-w-[1368px] fhd:max-w-[1460.8px] mx-auto;
} */

/* normal */
.section-container-wider-programs {
  @apply max-w-[88%] hd:max-w-[90%] xl:max-w-[1200px] hdl:max-w-7xl hd2xl:max-w-[1440px] fhd:max-w-[1536px] mx-auto;
}

.client-list {
  /* column-count: 2; Set the number of columns */
  column-gap: 20px; /* Adjust the gap between columns */
  /* width: 65%; */
  /* Additional styling (optional) */
  font-size: 16px;
  line-height: 1.5;
}

.client-list > p {
  width: 100%;
  /* height: 2000px; */
  color: #6d6f71;
  /* break-inside: avoid-column; */
  /* @apply flex flex-col flex-wrap gap-x-2; */
}

.hero-content > p {
  font-size: 20px;
}

.activities-styles .image-style-side {
  float: right;
  width: 40vw;
  margin-left: 2vw;
  /* border-radius: 50px; */
}

.activities-styles .image-style-align-left {
  float: left;
  width: 26.04vw;
  margin-left: 2vw;
}

.activities-styles .image-style-align-left img {
  max-width: 100%;
  height: auto;
  max-height: 400px;
}

.activities-styles .image-style-side img {
  max-width: 100%;
  height: auto;
  border-radius: 30px;
}

.activities-styles {
  height: 100%;
}

.activities-styles  p {
  font-size: 1.7vw;
  line-height: 110%;
  font-weight: 400;
  color: #064568;
}

.activities-styles .table {
  /* padding-top: 20px; */
  float: none!important;
  width: 100%!important;
  min-width: 58%!important;
}

.table > table {
  width: 100%;
  max-width: 100%;
  /* margin: auto; */
  border-collapse: collapse;
}

.table > table > thead {
  text-transform: uppercase;
  font-size: 2vw;
  font-family: "KGHappy-solid", sans-serif;
  color: white;
}

.table > table > tbody {
  font-size: 1.8vw!important;
  color: white;
}

.table > table > thead > tr > th {
  border: 0.3vw solid white;
  padding: 1vw;
  font-family: "KGHappy-solid", sans-serif;
  text-align: center;
}

.table > table > thead > tr > th > span {
  font-family: "KGHappy-solid", sans-serif;
}

.table > table > tbody > tr > td {
  border: 0.3vw solid white;
  font-size: 1.25vw!important;
}

.table > table > tbody > tr > td > p > span {
  text-transform: capitalize;
  font-size: 1.25vw!important;
  color: white;
}

.table > table > tbody > tr > td > p {
  padding: 10px 0;
  font-size: 1.25vw!important;
}

.green-column-table {
  padding: 25px;
}

.blue-column-table {
  padding: 10px 0;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .activities-styles .image-style-side {
    float: right;
    width: 40vw;
    margin-left: 2vw;
    /* border-radius: 50px; */
  }
  
  .activities-styles .image-style-align-left {
    float: left;
    width: 26.04vw;
    margin-left: 2vw;
  }
  
  .activities-styles .image-style-align-left img {
    max-width: 100%;
    height: auto;
    max-height: 400px;
  }
  
  .activities-styles .image-style-side img {
    max-width: 100%;
    height: auto;
    border-radius: 30px;
  }
  
  .activities-styles {
    height: 100%;
  }
  
  .activities-styles  p {
    font-size: 12px;
    line-height: 110%;
    font-weight: 400;
    color: #064568;
  }
  
  .activities-styles .table {
    /* padding-top: 20px; */
    float: none!important;
    width: 100%!important;
    min-width: 58%!important;
  }
  
  .table > table {
    width: 100%;
    max-width: 100%;
    /* margin: auto; */
    border-collapse: collapse;
  }
  
  .table > table > thead {
    text-transform: uppercase;
    font-size: 12px;
    font-family: "KGHappy-solid", sans-serif;
    color: white;
  }
  
  .table > table > tbody {
    font-size: 12px!important;
    color: white;
  }
  
  .table > table > thead > tr > th {
    border: 0.3vw solid white;
    padding: 1vw;
    font-family: "KGHappy-solid", sans-serif;
  }
  
  .table > table > thead > tr > th > span {
    font-family: "KGHappy-solid", sans-serif;
  }
  
  .table > table > tbody > tr > td {
    border: 0.3vw solid white;
    font-size: 12px!important;
  }
  
  .table > table > tbody > tr > td > p > span {
    text-transform: capitalize;
    font-size: 12px!important;
    color: white;
  }
  
  .table > table > tbody > tr > td > p {
    padding: 10px 0;
    font-size: 12px!important;
  }
  
  .green-column-table {
    padding: 25px;
  }
  
  .blue-column-table {
    padding: 12px 0;
    text-align: center;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
  .activities-styles .image-style-side {
    float: right;
    width: 40vw;
    margin-left: 2vw;
    /* border-radius: 50px; */
  }
  
  .activities-styles .image-style-align-left {
    float: left;
    width: 26.04vw;
    margin-left: 2vw;
  }
  
  .activities-styles .image-style-align-left img {
    max-width: 100%;
    height: auto;
    max-height: 400px;
  }
  
  .activities-styles .image-style-side img {
    max-width: 100%;
    height: auto;
    border-radius: 30px;
  }
  
  .activities-styles {
    height: 100%;
  }
  
  .activities-styles  p {
    font-size: 2vw;
    line-height: 110%;
    font-weight: 400;
    color: #064568;
  }
  
  .activities-styles .table {
    /* padding-top: 20px; */
    float: none!important;
    width: 100%!important;
    min-width: 58%!important;
  }
  
  .table > table {
    width: 100%;
    max-width: 100%;
    /* margin: auto; */
    border-collapse: collapse;
  }
  
  .table > table > thead {
    text-transform: uppercase;
    font-size: 2.5vw;
    font-family: "KGHappy-solid", sans-serif;
    color: white;
  }
  
  .table > table > tbody {
    font-size: 1.8vw!important;
    color: white;
  }
  
  .table > table > thead > tr > th {
    border: 0.3vw solid white;
    padding: 1vw;
    font-family: "KGHappy-solid", sans-serif;
  }
  
  .table > table > thead > tr > th > span {
    font-family: "KGHappy-solid", sans-serif;
  }
  
  .table > table > tbody > tr > td {
    border: 0.3vw solid white;
    font-size: 1.7vw!important;
  }
  
  .table > table > tbody > tr > td > p > span {
    text-transform: capitalize;
    font-size: 1.7vw!important;
    color: white;
  }
  
  .table > table > tbody > tr > td > p {
    padding: 10px 0;
    font-size: 1.7vw!important;
  }
  
  .green-column-table {
    padding: 25px;
  }
  
  .blue-column-table {
    padding: 10px 0;
    text-align: center;
  }
}

@media only screen and (min-width: 1600px) {
  .activities-styles  p {
    font-size: 28px;
    line-height: 110%;
    font-weight: 400;
    color: #064568;
  }

  .activities-styles .table {
    /* padding-top: 20px; */
    float: none!important;
    width: 100%!important;
    min-width: 58%!important;
  }
  
  .table > table {
    width: 100%;
    max-width: 100%;
    /* margin: auto; */
    border-collapse: collapse;
  }
  
  .table > table > thead {
    text-transform: uppercase;
    font-size: 24px;
    font-family: "KGHappy-solid", sans-serif;
    color: white;
  }
  
  .table > table > tbody {
    font-size: 24px!important;
    color: white;
  }
  
  .table > table > thead > tr > th {
    border: 5px solid white;
    padding: 10px;
    font-family: "KGHappy-solid", sans-serif;
  }
  
  .table > table > thead > tr > th > span {
    font-family: "KGHappy-solid", sans-serif;
  }
  
  .table > table > tbody > tr > td {
    border: 5px solid white;
    font-size: 24px!important;
  }
  
  .table > table > tbody > tr > td > p > span {
    text-transform: capitalize;
    font-size: 24px!important;
    color: white;
  }
  
  .table > table > tbody > tr > td > p {
    padding: 10px 0;
    font-size: 24px!important;
  }
  
  .green-column-table {
    padding: 25px;
  }
  
  .blue-column-table {
    padding: 10px 0;
    text-align: center;
  }
}

.Hero-small-text p {
  font-size: 22px;
}

.breadcrumb-style {
  font-weight: 600;
  font-size: 14px;
  color: var(--cuzBlue);
  letter-spacing: 0.8px;
}

.swal2-container {
  z-index: 1000000 !important;
}